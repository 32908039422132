import * as React from "react"
import { Hero } from "../components/hero"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { YouTube } from "../components/youtube"

interface InstructionsProps {
  pageContext: any
  data: any
}

function renderTitleHTML(title: Array<any>) {
  return (
    <span>
      {title.map((t, i) => {
        if (t.type === "span") {
          return (
            <React.Fragment key={`${t.value}${i}`}>{t.value}</React.Fragment>
          )
        } else if (t.type === "link") {
          return (
            <a href={t.url} className="underline" key={`${t.url}${i}`}>
              {t.children[0].value}
            </a>
          )
        }
      })}
    </span>
  )
}
function renderTitleString(title: Array<any>): string {
  // #TODO: typescript
  return title.map((t) => {
    if (t.type === "span") {
      return t.value
    } else if (t.type === "link") {
      return t.children[0].value
    }
  })
}

export default function InstructionsPage({
  pageContext,
  data,
}: InstructionsProps) {
  const { i18n } = pageContext
  const instructionsPDF = data.allDatoCmsInstructionPdf.nodes
  const instructionsVideo = data.allDatoCmsInstructionVideo.nodes

  return (
    <>
      <Hero title={i18n.instructions.title}>
        <StaticImage
          className="!absolute z-0 w-full h-full"
          src="../assets/images/banner_split.jpg"
          objectPosition="70% 50%"
          alt=""
          quality={95}
        />
      </Hero>
      <div className="my-12 font-bold out-wrapper">
        <h2 className="text-4xl">{i18n.instructions.print}</h2>
        <ul className="pl-4 text-xl sm:pl-10">
          {instructionsPDF.map((instruction) => {
            return (
              <li className="my-5" key={instruction.slug}>
                <a href={instruction.slug} className="flex py-2">
                  <div className="m-0 shrink-0">
                    <StaticImage src="../assets/images/pdf.png" alt="" />
                  </div>
                  <span className="pl-5">{instruction.text}</span>
                </a>
              </li>
            )
          })}
        </ul>
        <h2 className="mt-12 text-4xl font-semi-bold">
          {i18n.instructions.video}
        </h2>
        <ul className="pl-4 text-xl sm:pl-10">
          {instructionsVideo.map((instruction) => {
            return (
              <li className="my-4" key={instruction.youtubeId} id={instruction.youtubeId}>
                <h3 className="mt-12 text-3xl text-center">
                  {renderTitleHTML(
                    instruction.title.value.document.children[0].children
                  )}
                </h3>
                <div className="max-w-4xl mx-auto my-4">
                  <YouTube
                    videoID={instruction.youtubeId}
                    title={renderTitleString(
                      instruction.title.value.document.children[0].children
                    )}
                  />
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export const query = graphql`
  query InstructionQuery($lang: String) {
    allDatoCmsInstructionPdf(
      filter: { locale: { eq: $lang } } # sort: { order: ASC }
    ) {
      nodes {
        slug
        text
      }
    }
    allDatoCmsInstructionVideo(
      filter: { locale: { eq: $lang } } # sort: { fields: order }
    ) {
      nodes {
        locale
        youtubeId
        title {
          value
        }
      }
    }
  }
`
